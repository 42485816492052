import coreToggle from '@nrk/core-toggle'
import css from './core-masthead.css'
const KEY = 'nrk-masthead'

init() // Try immediate execution

export default function init () {
  const style = typeof document !== 'undefined' && document.querySelector(`.${KEY} style`)

  if (style) { // Move CSS to beginning of <head> for easier restyling
    document.head.insertAdjacentElement('afterbegin', style).appendChild(document.createTextNode(css))
    document.querySelector(`.${KEY}__bars`).outerHTML = `<button class="${KEY}__bars" aria-label="Meny"></button>`
    coreToggle(`.${KEY}__toggle,.${KEY}__bars`, {popup: true})
    districts()
    login()
  }
}

function districts () {
  try { // Safely use localStorage cross domain
    const key = `data-${KEY}-district`
    const val = window.localStorage.getItem(key)
    const districts = [].slice.call(document.querySelectorAll(`.${KEY}-districts a`))

    // Set content of district using .some to exit on first match
    districts.some((el) => {
      el.setAttribute(key, '')
      if (el.textContent === val) districts[0].outerHTML = el.outerHTML
    })

    document.addEventListener('click', (event) => {
      for (let el = event.target; el; el = el.parentElement) {
        if (el.hasAttribute(key)) window.localStorage.setItem(key, el.textContent)
      }
    })
  } catch (err) { }
}

function login () {
  const logout = document.querySelector(`.${KEY}__logout`)
  const login = document.querySelector(`.${KEY}__login`)
  const auth = document.querySelector(`.${KEY}__auth`)
  const ajax = new window.XMLHttpRequest()
  let config

  ajax.onload = () => {
    try { config = JSON.parse(ajax.responseText) } catch (err) { return }
    const origin = String(config.checkSessionIframeUrl.match(/https:\/\/[^/]+/))
    const iframe = document.createElement('iframe')

    const user = config.sessionState && config.sessionState !== 'none' && config.loggedInUser
    const message = `${config.clientId || 'none'} ${config.sessionState}`
    const frequency = 2000
    // let firstMessage = true

    login.href = config.loginUrl
    logout.action = config.logoutUrl
    logout.elements._csrf.value = config._csrf || ''
    logout.querySelector('button').textContent = user
    logout.style.display = user ? '' : 'none'
    login.style.display = user ? 'none' : ''
    auth.removeAttribute('hidden')

    iframe.src = config.checkSessionIframeUrl
    iframe.style.display = 'none'
    iframe.onload = () => {
      const timer = setInterval(() => iframe.contentWindow.postMessage(message, origin), frequency)

      window.addEventListener('message', (event) => {
        if (event.origin !== origin) return
        if (event.data !== 'unchanged') clearInterval(timer)
        if (event.data === 'changed') window.location.replace(config.loginUrl) // && firstMessage
        if (event.data === 'ended') {
          logout.insertAdjacentHTML('beforeend', '<input type="hidden" name="localLogout" value="true">')
          logout.submit()
        }
        // firstMessage = false // TODO put in if block?
      })
    }
    document.body.appendChild(iframe)
  }
  if (auth) {
    ajax.open('GET', '/sso-config', true)
    ajax.send()
  }
}
